import React from "react";
import "./App.css";
import { createMuiTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import Headder from "./componente/vjager/headder-jager.js"
import Accordion from "./componente/vjager/accordion-regular.js";
import Footer from "./componente/vjager/footer-jager.js"


const theme = createMuiTheme({
  typography: {
    fontFamily: [

      'monospace'
    ].join(','),
  }
});

function App() {
  return (
    <ThemeProvider theme={theme}>
    <div>
      <Headder/>
      <Accordion/>
      <Footer/>
    </div>
    </ThemeProvider>
  );
}

export default App;