import "./footer-jager.css";
const Footer = () => {
  return (
    <div>
      <footer className="App-footer">
        <span className={"span-footer"}>#</span>CARD PAYMENTS
        <span className={"span-footer"}>#</span>PLATA CU CARD
        <span className={"span-footer"}>#</span>
      </footer>
    </div>
  );
};

export default Footer;
